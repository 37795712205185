import './skip-link-focus-fix';
import 'script-loader!slick-carousel';
import './jquery.fancybox.pack.js';
import './jquery.fancybox-buttons.js';
import './jquery.fancybox-media.js';
import './jquery.fancybox-thumbs.js';
import './jquery.fsMediaGallery';
import gsap from 'gsap';
import AOS from 'aos';
import 'script-loader!slick-lightbox';

(function ($) {

	var windowHeight = $(window).height();

	AOS.init({ once: true, duration: 500 });

	$(document).ready(function () {
		menuToggle();
		headerEffects();
		accordionShortcode();
		portfolioSlider();
		portfolioAccordion();
		parallaxEffects();
	});

	// Header effects
	function headerEffects() {
		var didScroll,
			lastScrollTop = 0,
			delta = 5,
			navbar = $('#masthead'),
			navbarHeight = navbar.outerHeight();

		$(window).scroll(function () {
			didScroll = true;

			var scrollPosition = $(window).scrollTop();

			if (scrollPosition > 100)
				navbar.addClass('site-header--scrolled');
			else
				navbar.removeClass('site-header--scrolled');
		});

		setInterval(function () {
			if (didScroll) {
				hasScrolled();
				didScroll = false;
			}
		}, 200);

		function hasScrolled() {
			var st = $(window).scrollTop();

			// Make sure they scroll more than delta
			if (Math.abs(lastScrollTop - st) <= delta)
				return;

			// If they scrolled down and are past the navbar, add class .nav-up.
			// This is necessary so you never see what is "behind" the navbar.
			if (st > lastScrollTop && st > navbarHeight) {
				// Scroll Down
				$('#masthead').removeClass('nav-down').addClass('nav-up');

			} else {
				// Scroll Up
				if (st + $(window).height() < $(document).height()) {
					$('#masthead').removeClass('nav-up').addClass('nav-down');
				}
			}

			lastScrollTop = st;
		}

		$(window).scroll();

		// browser window scroll (in pixels) after which the "back to top" link is shown
		var offset = 300,
			//duration of the top scrolling animation (in ms)
			scroll_top_duration = 300,
			//grab the "back to top" link
			$back_to_top = $('.cd-top');
		//hide or show the "back to top" link
		$(window).scroll(function () {
			($(this).scrollTop() > offset) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
		});
		//smooth scroll to top
		$back_to_top.on('click', function (event) {
			event.preventDefault();
			$('body,html').animate({
				scrollTop: 0,
			}, scroll_top_duration
			);
		});
	}

	// Parallax effects
	function parallaxEffects() {

		document.addEventListener('aos:in:line-animate', ({ detail }) => {
			$(detail).addClass('line-animate');
		});

		document.addEventListener('aos:in:line-shortcode', ({ detail }) => {
			$(detail).addClass('line-shortcode--animate');
		});

		$(window).scroll(function () {
			Parallax($('#page-header-title'), 0.05, 0.4, 'up');
			Parallax($('.full-text__bg-image--parallax'), 0.1, 0.4, 'up');
			Parallax($('.fifty-layered__bg-image'), 0.1, 0.4, 'up');
			Parallax($('.fifty-layered__image'), 0.1, 0.4, 'up');
			Parallax($('.sixty-image__bg-image--parallax'), 0.1, 0.4, 'up');
			Parallax($('.fifty-image__image--parallax'), 0.1, 0.4, 'up');
			Parallax($('.text-image__bg-image--parallax'), 0.1, 0.4, 'up');
		});
	}

	// Header menu toggle
	function menuToggle() {
		$("#menu-toggle").on('click', function () {
			$(this).toggleClass('site-header__menu-toggle--open');
			$("#menu-primary-menu").toggleClass('site-header__menu--open');
		});

		$(".submenu-toggle").on('click', function () {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__toggle').on('click', function () {
			var accordion = $(this).parent('.accordion-shortcode'),
				content = $(this).prev('.accordion-shortcode__content');

			content.slideToggle();
			accordion.toggleClass('accordion-shortcode--open');

			if ($(this).text() == 'Close') {
				var title = $(this).data('title');
				$(this).text(title);
			} else {
				$(this).text('Close');
			}
		});
	}

	// Portfolio accordion
	function portfolioAccordion() {
		$('.portfolio-single__accordion-toggle').on('click', function () {
			var content = $(this).next('.portfolio-single__accordion-content');

			content.slideToggle();
			$(this).toggleClass('portfolio-single__accordion-toggle--open');
		});
	}

	// Portfolio slider
	function portfolioSlider() {
		if (jQuery.fn.fsMediaGallery && $('#portfolio-page-gallery').length ) {
			$('#portfolio-page-gallery').fsMediaGallery({});
			$(window).resize();
		}
		if (jQuery.fn.fancybox ) {
			$('a[data-fancybox="fancybox"]').fancybox({
				padding: 0,
				margin: 0,
				loop: true,
				openSpeed: 500,
				closeSpeed: 500,
				nextSpeed: 500,
				prevSpeed: 500,
				afterLoad: function () {
					$('.fancybox-inner').click(function () {
						if (click == true) {
							$('body').toggleClass('fancybox-full');
						}
					})
				},
				beforeShow: function () {
					$('body').addClass('fancybox-lock');
				},
				afterClose: function () {
					$('body').removeClass('fancybox-lock');
				},
				tpl: {
					image: '<div class="fancybox-image" style="background-image: url(\'{href}\');"/>',
					iframe: '<span class="iframe-before"/><iframe id="fancybox-frame{rnd}" width="60%" height="60%" name="fancybox-frame{rnd}" class="fancybox-iframe" frameborder="0" vspace="0" hspace="0"' + ($.browser.msie ? ' allowtransparency="true"' : '') + '/>'
				},
				helpers: {
					title: null,
					thumbs: {
						height: 50,
						width: 80
					},
					overlay: {
						css: {
							'background': '#191919'
						}
					}
				}
			});
		}
	}

	function Parallax(el, ind, duration, direction) {
		if (el.length) {
			el.each(function () {
				var offset = (window.pageYOffset + windowHeight / 2 - $(this).offset().top) * ind;

				if (direction == 'up') {
					offset = Math.min(offset * -1, 0);
				} else {
					offset = Math.max(offset, 0);
				}

				gsap.to($(this), {
					duration: duration,
					y: offset,
				});
			});
		}
	}

	function ParallaxScale(el) {
		if (el.length) {
			el.each(function () {
				if ((window.pageYOffset + windowHeight) > $(this).offset().top) {
					var offset = 1 + (window.pageYOffset + windowHeight - $(this).offset().top) * 0.0001;
				} else {
					var offset = 1;
				}
				TweenMax.to($(this), 0.6, {
					scale: offset,
				});
			});
		}
	}

})(jQuery);