jQuery.fn.fsMediaGallery = function(config){
	jQuery.extend(config, {
	  scrollSpeed: 0.05,
	  scrollDelay: 500,
	  mobileWidth: null,
	  useArrows: true,
	  hideEmptyCaption: false,
	  infiniteScrolling: true,
	  gallerySelect: '.mediagallery',
	  galleryItemSelect: '.mediagallery .mediagallery-item',
	  galleryZoomSelect: '.page-gallery_img a',
	}, config);
	var self = this
	  , tHeight = 0
	  , sPadding = 5
	  , scrollDuration = 50
	  , scrollSpeed = .1
	  , gHeight
	  , sOffset
	  , sHeight
	  , wWidth
	  , sWidth
	  , sRatio
	  , isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	var mediaGallery = jQuery(self)
	  , mediaGalleryZoom = jQuery(self).find(config.galleryZoomSelect)
	  , mediaGalleryList = jQuery(self).find(config.gallerySelect)
	  , mediaGalleryListWrap = jQuery(self).find(config.galleryItemSelect).first().parent()
	  , mediaGalleryListItems = jQuery(self).find(config.galleryItemSelect)
	  , mediaGalleryListItemWidth = jQuery(self).find(config.galleryItemSelect).first().width()
	  , mediaGalleryMobileWidth;
	mediaGalleryList.wrap('<div class="mediagallery_outer">');
	var mediaGalleryOuter = mediaGalleryList.closest('.mediagallery_outer');
  
	/*
	 * Window Resize
	 */
	var doResizePortfolioGallery = function (){
	  wWidth  = jQuery(window).width();
	  gHeight = jQuery(window).height() - tHeight - jQuery('#masthead').outerHeight();
	  sHeight = mediaGalleryList.height();
	  sWidth  = mediaGalleryListWrap.width();
	  sRatio  = (sWidth > wWidth ? ((sWidth - (wWidth - sPadding)) / wWidth) : 0);
	  mediaGallery.css('height', gHeight);
	  mediaGalleryZoom.css('height', gHeight - sHeight);
	  mediaGalleryListItemWidth = jQuery(self).find(config.galleryItemSelect).first().width();
	  mediaGalleryList.stop().data('scroll', false);
	  mediaGalleryList.each(function(){ jQuery(this).scrollLeft((sWidth / 2) - (wWidth / 2)); });
	  mediaGalleryOuter.find('.mediagallery-arrow').css('height', sHeight);
	  mediaGalleryMobileWidth = config.mobileWidth ? config.mobileWidth : ((mediaGalleryListItemWidth + sPadding) * 3);
	  if (isMobile || wWidth < mediaGalleryMobileWidth){
		mediaGalleryList.css('overflow-x', 'scroll');
	  }
	  else {
		mediaGalleryList.css('overflow-x', 'hidden');
	  }
	  doExtendPortfolioGallery();
	  setTimeout(function(){ sOffset = mediaGalleryList.offset(); }, 1000);
	}
	var doExtendPortfolioGallery = function(){
	  var runOff = 0, originalCount = mediaGalleryListItems.length;
	  while( runOff++ < 10 && originalCount > 0 && mediaGalleryListWrap.width() < (jQuery(window).width() + (mediaGalleryListItemWidth * 2)) ){
		var pointer = 0;
		while( pointer < originalCount ) {
		  jQuery(mediaGalleryListItems.get(pointer++)).clone().insertAfter(mediaGalleryListItems.last());
		  mediaGalleryListItems = jQuery(self).find(config.galleryItemSelect);
		}
	  }
	}
  
	var scrollEvent = function( scrollLeft, onComplete ){
	  if (config.infiniteScrolling) {
		var scrollDir = scrollLeft < mediaGalleryList.scrollLeft() ? -1 : 1;
	  }
	  mediaGalleryList
		.stop()
		.animate({
		  scrollLeft: scrollLeft
		}, {
		  duration: scrollDuration,
		  easing: 'linear',
		  complete: function(){
			if (config.infiniteScrolling) {
			  var runOff = 0;
			  while( runOff++ < 10 && Math.abs(scrollLeft - ((sWidth / 2) - (wWidth / 2))) > (mediaGalleryListItemWidth + sPadding) ){
				if (scrollDir < 0) {
				  scrollLeft += (mediaGalleryListItemWidth + sPadding);
				  mediaGalleryList.scrollLeft(scrollLeft);
				  mediaGalleryListWrap.find('.mediagallery-item').last().prependTo(mediaGalleryListWrap);
				}
				else if(scrollDir > 0){
				  scrollLeft -= (mediaGalleryListItemWidth + sPadding);
				  mediaGalleryList.scrollLeft(scrollLeft);
				  mediaGalleryListWrap.find('.mediagallery-item').first().appendTo(mediaGalleryListWrap);
				}
			  }
			}
			if (typeof onComplete == 'function') {
			  onComplete();
			}
		  }
		});
	}
  
	/*
	 * Infinite Scrolling
	 */
	var smoothScrollInfinite = function(el){
	  var smoothScrollToInfiniteTimer;
	  var smoothScrollToInfiniteEvent = function(){
		if (wWidth < mediaGalleryMobileWidth) return;
		var clientX = mediaGalleryList.data('clientX');
		var scrollLeft, dirMove, dir = (clientX < (wWidth * 0.3))
				? -(1-(clientX / (wWidth * 0.3)))
				: (
				  (clientX > (wWidth * 0.7))
				  ? (1-(((wWidth * 0.3) - (clientX - (wWidth * 0.7))) / (wWidth * 0.3)))
				  : 0
				  );
		if (dir) {
		  mediaGalleryList.data('scroll', true);
		  dirMove = (dir * (mediaGalleryListItemWidth + sPadding) * scrollSpeed);
		  scrollLeft = mediaGalleryList.scrollLeft() + dirMove;
		  scrollEvent( scrollLeft, function(){
			if (mediaGalleryList.data('scroll')) {
			  smoothScrollToInfiniteEvent();
			}
		  });
		}
		else {
		  mediaGalleryList.data('scroll', false);
		}
	  }
	  jQuery(document)
		.on('mousemove', function(ev){
		  if (mediaGalleryList.data('scroll')) {
			var top = jQuery(window).scrollTop() + ev.clientY;
			if (top < sOffset.top || top > sOffset.top + sHeight) {
			  mediaGalleryList.stop().data('scroll', false);
			}
		  }
		});
	  jQuery(el)
		.scrollLeft((sWidth / 2) - (wWidth / 2))
		.css('overflow', 'hidden')
		.on('mouseup', function(ev){
		  mediaGalleryList.stop().data('scroll', false);
		})
		.on('mousemove', function(ev){
		  mediaGalleryList.data('clientX', ev.clientX);
		  if (!mediaGalleryList.data('scroll'))
			smoothScrollToInfiniteEvent();
		});
	}
  
	/*
	 * Scrolling between Boundaries
	 */
	var smoothScrollBoundary = function(el){
	  var smoothScrollBoundaryEvent = function(clientX){
		if (!sRatio) return;
		if (wWidth < mediaGalleryMobileWidth) return;
		var scrollLeft = (clientX < (wWidth * 0.05))
				? 0
				: (
				  (clientX > (wWidth * 0.95))
				  ? (sWidth - (wWidth - sPadding))
				  : (clientX * sRatio)
				  );
		mediaGalleryList.data('scroll', true);
		scrollEvent( scrollLeft, function(){
		  mediaGalleryList.data('scroll', false);
		});
	  }
	  jQuery(el)
		.css('overflow', 'hidden')
		.scrollLeft((sWidth / 2) - (wWidth / 2))
		.on('mousemove', function(ev){
		  mediaGalleryList.data('clientX', ev.clientX);
		  if (!mediaGalleryList.data('scroll'))
			smoothScrollBoundaryEvent(ev.clientX);
		});
	}
  
	/*
	 * Navigation Arrows
	 */
	if (config.useArrows) {
	  mediaGalleryOuter.append(
		jQuery('<div class="mediagallery-arrow left"></div>')
		  .on('click', function(){
			var scrollLeft = mediaGalleryList.scrollLeft() - (mediaGalleryListItemWidth + sPadding);
			mediaGalleryList.data('scroll', true);
			scrollEvent( scrollLeft, function(){
			  mediaGalleryList.data('scroll', false);
			});
		  })
		);
	  mediaGalleryOuter.append(
		jQuery('<div class="mediagallery-arrow right"></div>')
		  .on('click', function(){
			var scrollLeft = mediaGalleryList.scrollLeft() + (mediaGalleryListItemWidth + sPadding);
			mediaGalleryList.data('scroll', true);
			scrollEvent( scrollLeft, function(){
			  mediaGalleryList.data('scroll', false);
			});
		  })
		);
	}
  
	/*
	 * Initialize
	 */
	doResizePortfolioGallery();
	jQuery(window).resize(function(){ doResizePortfolioGallery(); })
	mediaGalleryListItems
	  .find('a')
	  .on('click', function(ev){
		var img = jQuery(this).attr('href'), caption = jQuery(this).attr('title');
		mediaGalleryZoom.attr('href', img).css('background-image', 'url('+img+')');
		mediaGalleryZoom.find('img').attr('src', img);
		var figCaption = mediaGalleryZoom.find('figcaption');
		figCaption.find('span').html(caption);
		if (config.hideEmptyCaption){
		  if (!figCaption.data('displayOrig'))
			figCaption.data('displayOrig', figCaption.css('display') ? figCaption.css('display') : 'block');
		  figCaption.css('display', (caption?figCaption.data('displayOrig'):'none'));
		}
		ev.preventDefault();
		return false;
	  });
  
	/*
	 * Mobile gets horizontal overflow
	 */
	if (!isMobile) {
	  if(config.infiniteScrolling)
		smoothScrollInfinite(mediaGalleryList);
	  else
		smoothScrollBoundary(mediaGalleryList);
	}
  }
  